import React from "react";
import { Stepper, Step, StepLabel, Box } from "@mui/material";

const ProgressStepper = ({ currentStep }) => {
  const steps = [{ label: "Time" }, { label: "Booth" }, { label: "Start" }];

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        activeStep={currentStep - 1}
        alternativeLabel
        sx={{
          "& .Mui-active": { color: "#212529" },
          "& .Mui-active .MuiStepIcon-root": { color: "#212529" },
          "& .Mui-completed": { color: "#212529" },
          "& .Mui-completed .MuiStepIcon-root": { color: "#212529" },
        }}
      >
        {steps.map((step) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default ProgressStepper;
