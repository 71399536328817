import logo from "../../images/logo.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ConfirmAction from "../../components/ConfirmAction";

// Utils
import { capitalizeFirstLetter } from "../../utils/textFormatter";
import { dateToTime } from "../../utils/timeFormatter";

// Components
import { qrCode, confirmActionIcon } from "../../components/Icons";

export default function ActiveSession({ sessionData }) {
  const [pageLoading, setPageLoading] = useState(false);
  const [selectedExtendTime, setSelectedExtendTime] = useState(null);
  const [showExtendConfirm, setShowExtendConfirm] = useState(false);
  const [showCheckoutConfirm, setShowCheckoutConfirm] = useState(false);

  const handleSelectTime = (time) => {
    setSelectedExtendTime(time);
  };

  // Function to confirm extension
  const confirmExtend = () => {
    console.log("Session extended by", selectedExtendTime);
    setShowExtendConfirm(false);
  };

  // Function to confirm checkout
  const confirmCheckout = () => {
    console.log("Session checked out");
    setShowCheckoutConfirm(false);
  };

  // Function to dismiss confirmations
  const dismissConfirmation = () => {
    setShowExtendConfirm(false);
    setShowCheckoutConfirm(false);
  };

  return (
    <section
      className="flex flex-col justify-center mt-1 mb-5
                md:mx-mdPagePadding md:px-mdPagePadding
                lg:mx-lgPagePadding lg:px-lgPagePadding
    "
    >
      {pageLoading && (
        <div className="fixed inset-0 bg-white/80 flex items-center justify-center z-50">
          <div className="flex flex-col items-center">
            <div className="w-10 h-10 border-t-2 border-b-2 border-gray-900 rounded-full animate-spin"></div>
            <p className="mt-4 text-gray-900">Loading...</p>
          </div>
        </div>
      )}
      {/* Logo */}
      <div className="flex flex-col justify-center items-center">
        <img src={logo} alt="logo" className="" style={{ width: "150px", height: "150px" }} />
      </div>

      {/* Active Session */}
      <div
        className="mx-smPagePadding bg-blue-50 border border-blue-200 flex flex-col justify-center items-center text-center py-5 px-2 rounded-primary
        md:px-mdPagePadding
        "
      >
        {/* {Icons.active} */}
        <h1 className="text-lg font-bold mb-2 py-2 md:text-l">Active Session</h1>
        <div className="px-3 py-2 border-l-2 border-gray-300 mb-2 w-full">
          <p className="text-textSecondary text-sm text-left">
            1. Scan the QR Code to unlock the booth. <br />
            2. Enjoy your session! <br />
            3. Use the QR Code to check out when done. <br />
          </p>
        </div>
        {/* Timer */}
        <span
          className="p-2 bg-white text-sm rounded-secondary border border-gray-200 mt-4 font-semibold
        md:text-base md:px-6 md:py-3
        "
        >
          Time Remaining: 10:12
        </span>
      </div>
      {/* Booth Details */}
      <div className="border-t border-b border-gray-200 py-5 mx-smPagePadding flex flex-col justify-center items-center mt-7">
        <div className="flex justify-between items-center mb-2">
          <div className="flex gap-8 text-textSecondary">
            <span>{sessionData ? `${capitalizeFirstLetter(sessionData.boothType)} Booth #${sessionData.boothId}` : ""}</span>
            <span className="font-semibold">Checkout - {sessionData ? `${dateToTime(sessionData.endTime)}` : ""}</span>
          </div>
        </div>
        <div className="flex items-center gap-3 text-textSecondary">
          McCormick
          <div className="h-4 w-px bg-gray-300"></div>
          {sessionData ? sessionData.boxPlacement : ""}
        </div>
      </div>
      {/* QR Code */}
      <div className="mx-smPagePadding flex flex-col justify-center items-center rounded-primary mt-4">{qrCode.activeSession(`check-in/`)}</div>
      {/* Extend Session */}
      <div
        className="flex flex-col justify-center items-center mt-4  mx-smPagePadding 
        md:py-5
      "
      >
        <div className="flex flex-row border-t border-b border-gray-200 py-4 w-full">
          <span className="font-semibold flex-shrink-0">Extend Session:</span>
          <ul className="flex flex-row gap-4 ml-4 text-gray-400 flex-grow">
            <li className={`cursor-pointer ${selectedExtendTime === "5min" ? "text-black" : "text-gray-400"}`} onClick={() => handleSelectTime("5min")}>
              5min
            </li>
            <li className={`cursor-pointer ${selectedExtendTime === "10min" ? "text-black" : "text-gray-400"}`} onClick={() => handleSelectTime("10min")}>
              10min
            </li>
            <li className={`cursor-pointer ${selectedExtendTime === "15min" ? "text-black" : "text-gray-400"}`} onClick={() => handleSelectTime("15min")}>
              15min
            </li>
          </ul>
        </div>
        {/* Check Out */}
        <div
          className="flex flex-row mt-4 gap-2 w-full
            md:mt-8 md:gap-4
            lg:gap-8
        "
        >
          <button
            className={`bg-white text-black border font-semibold border-gray-200 px-4 py-3 rounded-secondary w-full 
          ${!selectedExtendTime ? "opacity-50 cursor-not-allowed" : ""}
          `}
            disabled={!selectedExtendTime}
            onClick={() => setShowExtendConfirm(true)}
          >
            Extend
          </button>
        </div>
      </div>
      {/* ConfirmAction for extending session */}
      <ConfirmAction
        isVisible={showExtendConfirm}
        onConfirm={confirmExtend}
        onCancel={dismissConfirmation}
        message={`Extend the session by ${selectedExtendTime}?`}
        icon={confirmActionIcon.extend}
        confirmColor="purple"
      />
    </section>
  );
}
