import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";

// Components
import logo from "../../images/logo.png";
import ProgressStepper from "../../components/ProgressStepper";
import ContinueBtn from "../../components/ContinueBtn";

// Utils
import { generateTimeSlots, timeNow } from "./TimeScheduler.util";

export default function TimeScheduler() {
  const { locationId } = useParams();
  const durations = [15, 30, 60];

  const [time, setTime] = useState("");
  const [duration, setDuration] = useState(durations[0].toString());
  const [boothType, setBoothType] = useState("single");

  const navigate = useNavigate();

  const handleStartSession = () => {
    const startTime = timeNow();
    navigate(`/${locationId}/reservation/select-box`, { state: { startTime, duration, boothType } });
  };

  const continueBtn = () => {
    const startTime = time;
    navigate(`/${locationId}/reservation/select-box`, { state: { startTime, duration, boothType } });
  };

  return (
    <section
      className={`flex flex-col justify-center bg-white
    md:px-mdPagePadding md:mx-smPagePadding 
    lg:px-lgPagePadding lg:mx-lgPagePadding
    `}
    >
      {/* -- Progress Stepper -- */}
      <div className="mt-10">
        <ProgressStepper currentStep={1} />
      </div>
      {/* -- Logo -- */}
      <div className="flex flex-col justify-center items-center p-2">
        <img src={logo} alt="logo" className="" style={{ width: "150px", height: "150px" }} />
      </div>
      {/* -- Booth Type -- */}
      <div className="flex justify-center gap-4">
        <label className="cursor-pointer">
          <input type="radio" name="booth" value="single" className="peer hidden" defaultChecked={boothType === "single"} onChange={(el) => setBoothType(el.target.value)} />
          <span className="px-8 py-4 border peer-checked:border-black peer-checked:text-black border-gray-200 rounded-primary font-medium hover:bg-gray-50 hover:border-gray-300 hover:shadow-sm font-inter inline-block text-gray-500">
            Single-person
          </span>
        </label>
        <label className="cursor-pointer">
          <input type="radio" name="booth" value="multi" className="peer hidden" defaultChecked={boothType === "multi"} onChange={(el) => setBoothType(el.target.value)} />
          <span className="px-8 py-4 border peer-checked:border-black peer-checked:text-black border-gray-200 rounded-primary font-medium hover:bg-gray-50 hover:border-gray-300 hover:shadow-sm font-inter inline-block text-gray-500">
            Multi-person
          </span>
        </label>
      </div>
      {/* -- Duration -- */}
      <div className="flex justify-center items-center gap-6 mt-8 font-inter border-b border-gray-200 py-4 m-5 border-t">
        {durations.map((durationValue) => (
          <label key={durationValue} className="cursor-pointer">
            <input
              type="radio"
              name="duration"
              value={durationValue}
              className="peer hidden"
              checked={duration === durationValue.toString()}
              onChange={(el) => setDuration(el.target.value)}
            />
            <span className="peer-checked:text-black peer-checked:font-medium text-gray-400 hover:text-black">{durationValue} min</span>
          </label>
        ))}
      </div>
      {/* -- Start Now -- */}
      <div className="flex justify-center mt-2 px-4">
        <button
          className="w-full max-w-md px-2 py-4 border border-secondary rounded-primary font-inter font-semibold hover:bg-gray-50"
          onClick={async () => handleStartSession()}
        >
          Start now
        </button>
      </div>
      {/* -- Time Slots -- */}
      <div>
        <div className="grid  grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 px-4 mt-6" style={{ marginBottom: "130px" }}>
          {generateTimeSlots(duration, locationId).map((timeSlot) => (
            <button
              key={timeSlot}
              className={`px-4 py-3 border rounded-secondary font-inter ${
                time === timeSlot ? "border-black text-black font-medium" : "border-gray-200 text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:shadow-sm"
              }`}
              onClick={() => setTime(timeSlot)}
            >
              {timeSlot}
            </button>
          ))}
        </div>
      </div>
      <ContinueBtn btnBack={false} disabled={!time || !duration || !boothType} onClick={continueBtn} />
    </section>
  );
}
