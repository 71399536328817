import moment from "moment";

export const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
};

export const dateToTime = (date, locationId) => {
  if (locationId === "0500550214") return moment(date).tz("America/Chicago").format("hh:mma");
  return moment(date).format("hh:mma");
};
