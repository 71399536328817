import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Paper, Typography, Box, Alert, Divider, CircularProgress, Button } from "@mui/material";
import { Timer, AttachMoney, LockOpen, CheckCircle, Warning, PhoneIphone, Logout } from "@mui/icons-material";
import { Link as LinkIcon } from "@mui/icons-material";
import { getCheckoutInfo } from "../../services/api";
import { locations } from "../../utils/locaitons.util";

export default function Checkout() {
  const { token } = useParams();
  const [session, setSession] = useState(null);
  const [duration, setDuration] = useState(0);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isCompleted, setIsCompleted] = useState(false);
  const [showLockCode, setShowLockCode] = useState(() => {
    // Retrieve the initial state from localStorage using the token
    return JSON.parse(localStorage.getItem(`showLockCode_${token}`)) || false;
  });

  useEffect(() => {
    const fetchCheckoutInfo = async () => {
      try {
        setIsLoading(true);
        const response = await getCheckoutInfo(token);

        if (!response.session) {
          setIsCompleted(true);
          return;
        }

        setSession(response.session);
        if (response.session.startTime) {
          const start = new Date(response.session.startTime);
          const now = new Date();
          const diffMinutes = Math.floor((now - start) / (1000 * 60));
          setDuration(diffMinutes);
        }
      } catch (err) {
        // If we get a 404, it means the session is completed
        if (err.message.includes("Session not found or already completed")) {
          setIsCompleted(true);
        } else {
          setError(err.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchCheckoutInfo();
    const interval = setInterval(fetchCheckoutInfo, 30000);
    return () => clearInterval(interval);
  }, [token]);

  useEffect(() => {
    if (session?.startTime) {
      const updateDuration = () => {
        const start = new Date(session.startTime);
        const now = new Date();
        const diffMinutes = Math.floor((now - start) / (1000 * 60));
        setDuration(diffMinutes);
      };

      updateDuration();
      const interval = setInterval(updateDuration, 60000);
      return () => clearInterval(interval);
    }
  }, [session]);

  const showCode = () => {
    if (session?.lockCode) {
      setShowLockCode(true);
      localStorage.setItem(`showLockCode_${token}`, JSON.stringify(true));
    }
  };

  if (isLoading) {
    return (
      <Container maxWidth="sm">
        <Box
          sx={{
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (isCompleted || session?.status === "abandoned") {
    return (
      <Container maxWidth="sm">
        <Box
          sx={{
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Paper elevation={3} sx={{ p: 4, width: "100%", textAlign: "center" }}>
            <CheckCircle sx={{ fontSize: 64, color: "success.main", mb: 2 }} />
            <Typography variant="h4" component="h1" gutterBottom>
              Session Complete!
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
              Thank you for using QuietBox. Your session has been completed successfully.
            </Typography>
            <Alert severity="success" sx={{ mb: 3 }}>
              Your payment has been processed and a receipt has been sent to your phone.
            </Alert>
            <Typography variant="body2" color="text.secondary">
              Need help? Contact support at team@quietbox.com
            </Typography>
          </Paper>
        </Box>
      </Container>
    );
  }

  const estimatedCost = (duration * locations.getLocationCostPerMinute(session.locationId)).toFixed(2);

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper elevation={3} sx={{ p: 4, width: "100%" }}>
          <Typography variant="h4" component="h1" gutterBottom align="center">
            Session in Progress
          </Typography>

          {error && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {error}
              console.log(error);
            </Alert>
          )}

          {!showLockCode ? (
            <Alert severity="info" sx={{ mb: 4 }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <PhoneIphone />
                <Typography>We've sent a link to your phone. You can close this window and use that link to return anytime.</Typography>
              </Box>
            </Alert>
          ) : (
            <Alert severity="warning" sx={{ mt: 2 }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography>
                  1. Press the Yale button twice on the lock to activate the keypad
                  <br />
                  2. Enter this code to lock the door and end your session
                </Typography>
              </Box>
            </Alert>
          )}

          <Box sx={{ my: 4 }}>
            <Typography variant="h5" gutterBottom sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Timer color="primary" /> Duration
            </Typography>
            <Typography variant="h3" align="center" sx={{ my: 2 }}>
              {duration} min
            </Typography>
          </Box>

          <Divider sx={{ my: 3 }} />

          <Box sx={{ mb: 4 }}>
            <Typography variant="h5" gutterBottom sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <AttachMoney color="primary" /> Estimated Cost
            </Typography>
            <Typography variant="h4" align="center" sx={{ my: 2 }}>
              ${estimatedCost}
            </Typography>
            <Typography variant="body2" color="text.secondary" align="center">
              $0.50 per minute
            </Typography>
          </Box>

          <Box sx={{ mb: 4 }}>
            <Typography variant="h5" gutterBottom sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Logout color="primary" /> Ready to Leave?
            </Typography>
            <Box sx={{ mt: 2 }}>
              {!showLockCode ? (
                <Button variant="contained" fullWidth size="large" onClick={showCode} sx={{ borderRadius: "5px", padding: "13px" }} startIcon={<LinkIcon />}>
                  Checkout
                </Button>
              ) : (
                <Typography variant="h6" align="center" sx={{ border: "1px solid grey", borderRadius: "5px", padding: "10px" }}>
                  Checkout Code: {session.lockCode}
                </Typography>
              )}
            </Box>
            {!showLockCode ? (
              <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 2 }}>
                You'll receive your lock code on the checkout page
              </Typography>
            ) : (
              <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 2 }}>
                Your card will be charged once you lock the door
              </Typography>
            )}
          </Box>

          <Alert severity="info">Need help? Contact support at team@quietbox.com</Alert>
        </Paper>
      </Box>
    </Container>
  );

  // return (
  //   <Container maxWidth="sm">
  //     <Box
  //       sx={{
  //         minHeight: "100vh",
  //         display: "flex",
  //         alignItems: "center",
  //         justifyContent: "center",
  //       }}
  //     >
  //       <Paper elevation={3} sx={{ p: 4, width: "100%" }}>
  //         <Typography variant="h4" component="h1" gutterBottom align="center">
  //           Checkout
  //         </Typography>

  //         {error ? (
  //           <Alert severity="error" sx={{ mb: 3 }}>
  //             {error}
  //           </Alert>
  //         ) : (
  //           <>
  //             <Box sx={{ my: 4 }}>
  //               <Typography variant="h5" gutterBottom sx={{ display: "flex", alignItems: "center", gap: 1 }}>
  //                 <LockOpen color="primary" /> Lock Code
  //               </Typography>
  //               <Typography
  //                 variant="h2"
  //                 align="center"
  //                 sx={{
  //                   my: 2,
  //                   fontFamily: "monospace",
  //                   letterSpacing: "0.25em",
  //                 }}
  //               >
  //                 {session?.lockCode}
  //               </Typography>
  //               <Alert severity="warning" sx={{ mt: 2 }}>
  //                 <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
  //                   <Warning />
  //                   <Typography>
  //                     1. Press the Yale button twice on the lock to activate the keypad
  //                     <br />
  //                     2. Enter this code to lock the door and end your session
  //                   </Typography>
  //                 </Box>
  //               </Alert>
  //             </Box>

  //             <Divider sx={{ my: 3 }} />

  //             <Box sx={{ mb: 4 }}>
  //               <Typography variant="h5" gutterBottom sx={{ display: "flex", alignItems: "center", gap: 1 }}>
  //                 <Timer color="primary" /> Session Details
  //               </Typography>
  //               <Typography variant="h3" align="center" sx={{ my: 2 }}>
  //                 {duration} min
  //               </Typography>
  //             </Box>

  //             <Box sx={{ mb: 4 }}>
  //               <Typography variant="h5" gutterBottom sx={{ display: "flex", alignItems: "center", gap: 1 }}>
  //                 <AttachMoney color="primary" /> Final Cost
  //               </Typography>
  //               <Typography variant="h3" align="center" sx={{ my: 2 }}>
  //                 ${estimatedCost}
  //               </Typography>
  //               <Typography variant="body2" color="text.secondary" align="center">
  //                 Your card will be charged once you lock the door
  //               </Typography>
  //             </Box>

  //             <Alert severity="info">Thank you for using QuietBox! Don't forget any belongings.</Alert>
  //           </>
  //         )}
  //       </Paper>
  //     </Box>
  //   </Container>
  // );
}
