import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: { "Content-Type": "application/json" },
});

export const getBooths = async () => {
  try {
    console.log("api call was sent...");
    const response = await api.get("/api/booths/getAll");
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    console.log(errorMessage);
    throw new Error(errorMessage);
  }
};

export const addTimeSlots = async () => {
  try {
    console.log("api call was sent...");
    const response = await api.post("/api/booths/addTimeSlots");
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    throw new Error(errorMessage);
  }
};

export const registerUser = async (userData) => {
  try {
    const response = await api.post("/api/user/register", userData);
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    throw new Error(errorMessage);
  }
};

export const startSession = async (sessionData) => {
  try {
    const response = await api.post("/api/sessions/start", sessionData);
    return {
      ...response.data,
      sessionId: response.data.session._id,
    };
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    throw new Error(errorMessage);
  }
};

export const getActiveSession = async (userId) => {
  try {
    const response = await api.get(`/api/sessions/active/${userId}`);
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    throw new Error(errorMessage);
  }
};

export const getAvailableBooths = async (params) => {
  try {
    const response = await api.post("/api/sessions/getAvailableBooths", {
      startTime: params.startTime,
      duration: params.duration,
      boothType: params.boothType,
      locationId: params.locationId,
    });
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    throw new Error(errorMessage);
  }
};

export const getCheckoutInfo = async (token) => {
  try {
    const response = await api.get(`/api/sessions/checkout/${token}`);
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    throw new Error(errorMessage);
  }
};

export const reserveBooth = async (sessionData) => {
  try {
    const response = await api.post("/api/sessions/reserve", sessionData);
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    throw new Error(errorMessage);
  }
};

export const cancelLockedSession = async (sessionId) => {
  try {
    const response = await api.post("/api/sessions/cancelLocked", { sessionId });
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    throw new Error(errorMessage);
  }
};

export const checkTermsAndEmail = async (phone, email) => {
  try {
    const response = await api.post("/api/user/checkTermsAndEmail", { phone, email });
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    throw new Error(errorMessage);
  }
};

export const temproraryReservation = async (boothId, building, startTime, endTime, locationId) => {
  try {
    const response = await api.post("/api/sessions/temporaryReservation", { boothId, building, startTime, endTime, locationId });
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    throw new Error(errorMessage);
  }
};

export const getCheckInSession = async (sessionId) => {
  try {
    const response = await api.post(`/api/sessions/checkIn`, { sessionId });
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    throw new Error(errorMessage);
  }
};

export const sendCode = async (phone) => {
  try {
    const response = await api.post("/api/user/sendCode", { phone });
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    throw new Error(errorMessage);
  }
};

export const approveCode = async (phone, code) => {
  try {
    const response = await api.post("/api/user/approveCode", { phone, code });
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    throw new Error(errorMessage);
  }
};

export const cancelSession = async (sessionId, phoneNumber) => {
  try {
    const response = await api.post(`/api/sessions/cancel`, { sessionId, phoneNumber });
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    throw new Error(errorMessage);
  }
};

export const verifyBoothAvailability = async (boothId, time, endTime, locationId, sessionId) => {
  try {
    const response = await api.post("/api/sessions/verifyBoothAvailability", { boothId, time, endTime, locationId, sessionId });
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    throw new Error(errorMessage);
  }
};

// Dev

export const resetTimeSlots = async () => {
  try {
    const response = await api.post("/api/dev/resetTimeSlots");
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    throw new Error(errorMessage);
  }
};

export const updateTimeSlot = async () => {
  try {
    const response = await api.post("/api/dev/updateTimeSlot");
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    throw new Error(errorMessage);
  }
};
export default api;
