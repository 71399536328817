import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Paper, Typography, Box, Alert, CircularProgress } from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "../../components/PaymentForm";
import ProgressStepper from "../../components/ProgressStepper";
import { locations } from "../../utils/locaitons.util";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export default function PaymentCapture() {
  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isStripeLoading, setIsStripeLoading] = useState(true);

  // Get SetupIntent, userId, locationId, and lockId from navigation state
  const setupIntent = location.state?.setupIntent;
  const userId = location.state?.userId;
  const locationId = location.state?.locationId;
  const lockId = location.state?.lockId;

  useEffect(() => {
    if (!setupIntent || !userId || !locations.isValidLocationAndLock(locationId, lockId)) navigate("/register");
  }, [setupIntent, userId, navigate]);

  if (!setupIntent || !userId) return null;

  const options = { clientSecret: setupIntent, appearance: { theme: "stripe" }, loader: "auto" };

  return (
    <Container maxWidth="sm">
      <Box sx={{ minHeight: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Paper elevation={3} sx={{ p: 4, width: "100%" }}>
          <ProgressStepper currentStep={2} />
          <Typography variant="h4" component="h1" gutterBottom align="center">
            Add Payment Method
          </Typography>
          <Typography variant="body1" color="text.secondary" align="center" gutterBottom>
            Your card will not be charged yet
          </Typography>
          <Typography variant="body2" color="text.secondary" align="center" gutterBottom sx={{ mb: 3 }}>
            All payments are securely processed by Stripe
          </Typography>

          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}

          <Elements stripe={stripePromise} options={options}>
            <PaymentForm
              onError={setError}
              onSuccess={() => navigate("/session/new", { state: { userId, locationId, lockId } })}
              clientSecret={setupIntent}
              onReady={() => setIsStripeLoading(false)}
            />
          </Elements>

          {isStripeLoading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                p: 2,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Paper>
      </Box>
    </Container>
  );
}
