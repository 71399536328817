import logo from "../../images/logo.png";
import { useNavigate } from "react-router-dom";

// Utils
import { capitalizeFirstLetter } from "../../utils/textFormatter";
import { dateToTime } from "../../utils/timeFormatter";

// Components
import { Icons } from "../../components/Icons";

export default function SessionCancel({ sessionData }) {
  const navigate = useNavigate();

  return (
    <section
      className="flex flex-col justify-center mt-1 mb-4 
    md:mx-smPagePadding md:px-mdPagePadding
    lg:mx-lgPagePadding lg:px-lgPagePadding
    "
    >
      <div className="flex flex-col justify-center items-center">
        <img src={logo} alt="logo" className="" style={{ width: "150px", height: "150px" }} />
      </div>
      <div className="mx-smPagePadding bg-red-50 border border-red-200 flex flex-col justify-center items-center text-center py-5 px-2 rounded-primary">
        {Icons.cancel}
        <h1 className="text-2xl font-bold mb-2 py-2">Session cancelled</h1>
        <p>We hope to see you soon!</p>
      </div>
      <div className="border-t border-b border-gray-200 py-5 mx-smPagePadding flex flex-col justify-center items-center mb-9 mt-10">
        <div className="flex justify-between items-center mb-2">
          <div className="flex gap-8 text-textSecondary">
            <span>{sessionData ? `${capitalizeFirstLetter(sessionData.boothType)} Booth #${sessionData.boothId}` : ""}</span>
            <span>{sessionData ? `${dateToTime(sessionData.startTime)} - ${dateToTime(sessionData.endTime)}` : ""}</span>
          </div>
        </div>
        <div className="flex items-center gap-3 text-textSecondary">
          McCormick
          <div className="h-4 w-px bg-gray-300"></div>
          {sessionData ? sessionData.boxPlacement : ""}
        </div>
      </div>

      {/* New Reservation Button */}
      <div
        className="mx-smPagePadding bottom-0 left-0 right-0 flex flex-col justify-center items-center py-4 z-50 pointer-events-none 
      md:mx-mdPagePadding
      md:mt-10
      "
      >
        <button
          className={`text-black w-full p-3 mb-2 rounded-secondary font-semibold transition-colors pointer-events-auto border 
            border-gray-300 font-inter bg-white hover:bg-gray-100 hover:border-gray-400 hover:shadow-md`}
          onClick={() => navigate(`/0500550214/reservation/time-scheduler`)}
        >
          New Reservation
        </button>
        <p className="text-textSecondary text-xs md:text-sm md:mt-2">
          Need help?{" "}
          <a href="mailto:support@pebble.com">
            Contact support at <u>support@pebble.com</u>
          </a>
        </p>
      </div>
    </section>
  );
}
