import { useNavigate } from "react-router-dom";

const ContinueBtn = ({ btnBack, name = "Continue", onClick, disabled = false }) => {
  const navigate = useNavigate();

  return (
    <div
      className={`
       fixed bottom-0 left-0 right-0 flex justify-center items-center py-4 z-50 pointer-events-none
       md:px-20 md:left-mdPagePadding md:right-mdPagePadding 
       lg:px-10 lg:mx-lgPagePadding lg:left-lgPagePadding lg:right-lgPagePadding lg:bottom-10
    `}
    >
      {btnBack && (
        <button className={`ml-smPagePadding bg-white px-4 py-3 rounded-secondary border border-gray-400 pointer-events-auto hover:shadow-md`} onClick={() => navigate(-1)}>
          <i className="fas fa-arrow-left fa-lg"></i>
        </button>
      )}
      <button
        className={`${
          disabled ? "bg-gray-400" : "bg-black hover:bg-gray-700 hover:shadow-md"
        } text-white mx-smPagePadding py-3 md:py-3 rounded-secondary font-semibold transition-colors 
        pointer-events-auto font-inter md:w-3/4 w-full`}
        onClick={onClick}
        disabled={disabled}
      >
        {name}
      </button>
    </div>
  );
};

export default ContinueBtn;
