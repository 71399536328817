import logo from "../../images/logo.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// Utils
import { capitalizeFirstLetter } from "../../utils/textFormatter";
import { dateToTime } from "../../utils/timeFormatter";

// Components
import { confirmActionIcon, Icons } from "../../components/Icons";

export default function FinishedSession({ sessionData }) {
  const [pageLoading, setPageLoading] = useState(false);

  const navigate = useNavigate();

  return (
    <section
      className="flex flex-col justify-center mt-1 mb-5 mx-smPagePadding
                    md:mx-mdPagePadding md:px-mdPagePadding
                    lg:mx-lgPagePadding lg:px-lgPagePadding
        "
    >
      {pageLoading && (
        <div className="fixed inset-0 bg-white/80 flex items-center justify-center z-50">
          <div className="flex flex-col items-center">
            <div className="w-10 h-10 border-t-2 border-b-2 border-gray-900 rounded-full animate-spin"></div>
            <p className="mt-4 text-gray-900">Loading...</p>
          </div>
        </div>
      )}
      {/* Logo */}
      <div className="flex flex-col justify-center items-center">
        <img src={logo} alt="logo" className="" style={{ width: "150px", height: "150px" }} />
      </div>

      {/* Active Session */}
      <div
        className="border border-black flex flex-col justify-center items-center text-center py-5 px-2 rounded-primary
            md:px-mdPagePadding 
            "
      >
        {Icons.sessionFinished}

        <h1 className="text-lg font-bold mb-2 py-2 md:text-l">Session Finished</h1>
        <div className="px-3 py-2 mb-2 w-full ">Thank you for using Pebble!</div>
      </div>
      {/* Booth Details */}
      <div className="border-t border-b border-gray-200 py-5 flex flex-col justify-center items-center mt-7">
        <div className="flex justify-between items-center mb-2">
          <div className="flex gap-8 text-textSecondary">
            <span>{sessionData ? `${capitalizeFirstLetter(sessionData.boothType)} Booth #${sessionData.boothId}` : ""}</span>
            <span>{sessionData ? `${dateToTime(sessionData.startTime)} - ${dateToTime(sessionData.endTime)}` : ""}</span>
          </div>
        </div>
        <div className="flex items-center gap-3 text-textSecondary">
          McCormick
          <div className="h-4 w-px bg-gray-300"></div>
          {sessionData ? sessionData.boxPlacement : ""}
        </div>
      </div>

      {/* New Reservation Button */}
      <div
        className="flex flex-col justify-center items-center py-4 z-50 pointer-events-none mt-10
      md:px-16 md:mt-16
      lg:px-20 lg:mt-20
      "
      >
        <button
          className={`text-black w-full p-3 mb-2 rounded-secondary font-semibold transition-colors pointer-events-auto border border-gray-300 font-inter bg-white hover:bg-gray-700`}
          onClick={() => navigate(`/${sessionData.locationId}/reservation/time-scheduler`)}
        >
          New Reservation
        </button>
        <p className="text-textSecondary text-xs">
          Need help?{" "}
          <a href="mailto:support@pebble.com">
            Contact support at <u>support@pebble.com</u>
          </a>
        </p>
      </div>
    </section>
  );
}
