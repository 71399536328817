import { useEffect, useState } from "react";

export const communityGuidelines = `<strong>Check-In:</strong> Arrive within 10 minutes of your start time. If you don't, your booth will be released and you'll be charged in full.
<br /><br />
<strong>Cancellations:</strong> Please cancel at least 15 minutes before your session so your booth may be used by others. 
<br /><br />
<strong>On-Time Exits:</strong> Vacate within 2 minutes of your session ending to prevent delays for the next user.
<br /><br />
<strong>Overstaying:</strong> Overstaying may result in an account strike (3 strikes is a potential ban from our services)`;

export default function CommunityGuidelines({ onClose, completeReservation }) {
  const [isVisible, setIsVisible] = useState(false);
  const [agreeGuidelines, setAgreeGuidelines] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div
      className={`fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center transition-opacity duration-600 ${isVisible ? "opacity-100" : "opacity-0"}`}
      // style={{ zIndex: 1000 }}
      onClick={onClose}
    >
      <div
        className="bg-white p-6 rounded-secondary text-center max-w-md mx-4 relative
      md:max-w-xl "
        onClick={(e) => e.stopPropagation()}
      >
        {/* Header */}
        <div className="flex justify-between items-center">
          <h1 className="text-xl font-bold">Community Guidelines</h1>
          <button onClick={onClose} className="text-black hover:text-gray-700 text-3xl rounded-full">
            &times;
          </button>
        </div>
        {/* Community Guidelines */}
        <div className="bg-gray-50 rounded-secondary p-4 mt-6">
          <p
            className="text-xs text-left 
          md:text-sm
          "
            dangerouslySetInnerHTML={{ __html: communityGuidelines }}
          />
        </div>
        {/* Checkboxes */}
        <div className="mx-4">
          <div className="flex items-start mt-4">
            <input type="checkbox" id="agreeGuidelines" className="mr-2 mt-1" checked={agreeGuidelines} onChange={(e) => setAgreeGuidelines(e.target.checked)} />
            <label htmlFor="agreeGuidelines" className="text-sm text-left">
              I accept the Pebble policy and attest to being 18 years of age or older.
            </label>
          </div>
          <div className="text-left ml-6">
            <a href="https://www.pebble.com/policies/guest-terms" className="text-[12px] mt-1 md:text-sm">
              <u>Review Pebble policy</u>
            </a>
          </div>
          <div className="flex items-start mt-3">
            <input type="checkbox" id="agreeTerms" className="mr-2 mt-1" checked={agreeTerms} onChange={(e) => setAgreeTerms(e.target.checked)} />
            <label htmlFor="agreeTerms" className="text-sm text-left">
              I consent to receiving text messages from Pebble regarding this booking
            </label>
          </div>
        </div>
        {/* Bottom Button */}
        <div className="flex flex-col justify-center items-center px-4 mt-2">
          <button
            onClick={completeReservation}
            className={`w-full bg-primary text-white font-semibold px-14 py-3 mt-6 md:mt-10 rounded-secondary md:text-base ${
              !agreeGuidelines || !agreeTerms ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={!agreeGuidelines || !agreeTerms}
          >
            Accept
          </button>
          <p className="text-[10px] text-center mt-2">
            By proceeding from this step you agree to Pebble's <br /> Guest Terms and Privacy Policy
          </p>
        </div>
      </div>
    </div>
  );
}
