import moment from "moment-timezone";

export const generateTimeSlots = (duration, locationId) => {
  const timeSlots = [];
  // Get the current time in Central Time Zone using moment
  const currentTime = moment().tz("America/Chicago");
  const startTime = currentTime.clone().set({ hour: 10, minute: 0, second: 0 }); // Set start time to 10:00 AM

  const scheduleEndTime = currentTime.clone().set({ hour: 17, minute: 0, second: 0 }); // Set schedule end time to 5:00 PM

  // Calculate the end time based on the selected duration
  const endTime = scheduleEndTime.clone().subtract(parseInt(duration), "minutes");

  // Ensure we start from the current time if it's past 10 AM, rounding up to the next 15-minute increment
  let actualStartTime = moment.max(startTime, currentTime);
  if (actualStartTime.minute() % 15 !== 0) {
    actualStartTime.add(15 - (actualStartTime.minute() % 15), "minutes").seconds(0);
  }

  while (actualStartTime <= endTime) {
    const formattedTime = actualStartTime.format("h:mm a").toLowerCase();
    timeSlots.push(formattedTime);
    actualStartTime.add(15, "minutes");
  }
  return timeSlots;
};

export const timeNow = () => {
  return moment().tz("America/Chicago").format("h:mm a").toLowerCase();
};
