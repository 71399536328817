import logo from "../../images/logo.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
// Components
import { communityGuidelines } from "../../components/CommunityGuidelines";
import { capitalizeFirstLetter } from "../../utils/textFormatter";
import { Icons } from "../../components/Icons";
import { dateToTime } from "../../utils/timeFormatter";

export default function ConfirmReservation() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { sessionData } = state || {}; // Ensure sessionData is accessed safely
  const { boothId, boothType, boxPlacement, startTime, endTime } = sessionData || {};
  const { locationId } = useParams();

  if (!sessionData) navigate(`/${locationId}/reservation/time-scheduler`);

  useEffect(() => {
    if (!sessionData) navigate(`/${locationId}/reservation/time-scheduler`);
  }, [sessionData]);

  return (
    <section
      className="flex flex-col justify-center mt-1 mb-4 px-smPagePadding
    md:px-mdPagePadding 
    lg:px-lgPagePadding lg:mx-lgPagePadding 
    "
    >
      {/* Logo */}
      <div className="flex flex-col justify-center items-center">
        <img src={logo} alt="logo" className="" style={{ width: "150px", height: "150px" }} />
      </div>
      {/* Session Reserved */}
      <div className=" bg-green-200 flex flex-col text-sm md:text-lg md:text-md md:px-10 justify-center items-center text-center py-5 rounded-primary">
        {Icons.success}
        <h1 className="text-2xl font-bold mb-2 py-2">Session Reserved</h1>
        <p className="text-sm md:text-base mx-2">Your reservation has been confirmed! Check your messages for your reservation details.</p>
      </div>
      {/* Community Guidelines */}
      <h3 className="text-sm font-bold mt-6 px-2 md:text-lg md:mt-10">Community Guidelines</h3>
      <div className="text-left text-xs md:text-sm px-2 mt-4 bg-white rounded-secondary" dangerouslySetInnerHTML={{ __html: communityGuidelines }} />
      {/* Booth Details */}
      <div className="border-t border-b border-gray-200 py-5 flex flex-col justify-center items-center mb-9 mt-10">
        <div className="flex justify-between items-center mb-2">
          <div className="flex gap-8 text-textSecondary">
            <span>{sessionData ? `${capitalizeFirstLetter(sessionData.boothType)} Booth #${sessionData.boothId}` : ""}</span>
            <span>{sessionData ? `${dateToTime(sessionData.startTime)} - ${dateToTime(sessionData.endTime)}` : ""}</span>
          </div>
        </div>
        <div className="flex items-center gap-3 text-textSecondary">
          McCormick
          <div className="h-4 w-px bg-gray-300"></div>
          {boxPlacement}
        </div>
      </div>
      {/* New Reservation Button */}
      <div
        className=" bottom-0 left-0 right-0 flex flex-col justify-center items-center py-4 z-50 pointer-events-none
      md:px-16
      "
      >
        <button
          className={`text-black w-full p-3 mb-2 rounded-secondary font-semibold transition-colors pointer-events-auto border border-gray-300 font-inter bg-white hover:bg-gray-700`}
          onClick={() => navigate(`/${locationId}/reservation/time-scheduler`)}
        >
          New Reservation
        </button>
        <p className="text-textSecondary text-xs">
          Need help?{" "}
          <a href="mailto:support@pebble.com">
            Contact support at <u>support@pebble.com</u>
          </a>
        </p>
      </div>
    </section>
  );
}
