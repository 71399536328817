import moment from "moment";

export const formatTime = (timeStr) => {
  // Handle cases like "10 am" or "2 pm"
  if (typeof timeStr === "string" && timeStr.includes(" ")) {
    return timeStr.toLowerCase(); // Return as-is, just ensure lowercase
  }

  // For numeric time (when adding duration)
  if (typeof timeStr === "number") {
    const hours = timeStr;
    const period = hours >= 12 ? "pm" : "am";
    const formattedHour = hours > 12 ? hours - 12 : hours;
    return `${formattedHour}${period}`;
  }

  return timeStr; // fallback
};

export const getEndTime = (startTime, duration) => {
  // Parse the start time (handles "10:15 am" format)
  const startMoment = moment(startTime, ["h:mm a"]);

  // Add the duration (in hours)
  const endMoment = startMoment.add(duration, "minutes");

  // Format the end time to match our existing format (e.g., "2:15pm")
  return endMoment.format("h:mma").toLowerCase();
};

export const transformStartTime = (startTime) => {
  const startMoment = moment(startTime, ["h:mm a"]);
  return startMoment.format("h:mma").toLowerCase();
};
