import { formatIncompletePhoneNumber, parsePhoneNumberWithError as parsePhoneNumber } from "libphonenumber-js";
import { isEmail } from "validator";

export const handlePhoneChange = (element, phone, setPhone, setPhoneError) => {
  let value = element.target.value;
  const numericValue = value.replace(/\D/g, "");
  setPhoneError(false);

  if ((numericValue.startsWith("1") && numericValue.length > 11) || (!numericValue.startsWith("1") && numericValue.length > 10)) {
    return;
  }

  const formattedValue = formatIncompletePhoneNumber(numericValue, "US");

  if (value.length < phone.length) setPhone(value);
  else setPhone(formattedValue);
};

export const formatPhoneforApi = (phoneNumber) => {
  try {
    if (!phoneNumber.trim()) {
      return null;
    }

    const parsedPhoneNum = parsePhoneNumber(phoneNumber, "US");
    return parsedPhoneNum.format("E.164");
  } catch (error) {
    return null;
  }
};

export const handleEmailChange = (element, setEmail) => {
  const email = element.target.value;
  setEmail(email);
};

export const validateEmail = async (email) => {
  return isEmail(email);
};

export const validatePhone = async (phone) => {
  try {
    const parsedPhoneNum = parsePhoneNumber(phone, "US");
    const nationalNumber = parsedPhoneNum.nationalNumber;
    return !((!nationalNumber.startsWith("1") && nationalNumber.length < 10) || (nationalNumber.startsWith("1") && nationalNumber.length < 11));
  } catch (error) {
    return false;
  }
};
