import React from "react";

const ConfirmAction = ({ isVisible, onConfirm, onCancel, message, confirmColor, icon }) => {
  if (!isVisible) return null;

  // Define a function to get button classes based on confirmColor
  const getButtonClasses = (color) => {
    switch (color) {
      case "purple":
        return "bg-purple-50 text-black px-10 border border-purple-200 rounded-secondary shadow-sm";
      case "blue":
        return "bg-blue-50 text-black px-10 border border-blue-200 rounded-secondary shadow-sm";
      case "red":
        return "bg-red-50 text-black px-10 border border-red-200 rounded-secondary shadow-sm";
      default:
        return "bg-gray-50 text-black px-10 py-3 rounded-secondary border border-gray-300 shadow-sm";
    }
  };

  return (
    <div
      id="backdrop"
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-48 transition-opacity duration-300 ease-in-out shadow-lg"
      onClick={onCancel}
      style={{ opacity: isVisible ? 1 : 0 }}
    >
      <div
        className="bg-white p-8 rounded-secondary transition-transform duration-200 ease-in-out transform justify-center items-center flex flex-col shadow-lg
        md:p-12
        "
        style={{ transform: isVisible ? "scale(1)" : "scale(0.95)" }}
        onClick={(e) => e.stopPropagation()}
      >
        {icon}

        <h2 className="text-md text-center mt-4">{message}</h2>
        <div className="flex flex-row justify-around mt-4 gap-4">
          <button className={getButtonClasses(confirmColor)} onClick={onConfirm}>
            Yes
          </button>
          <button className="bg-gray-50 text-black px-10 py-3 rounded-secondary border border-gray-300 shadow-sm" onClick={onCancel}>
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmAction;
