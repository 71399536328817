import { useState } from "react";
import { resetTimeSlots, updateTimeSlot } from "../services/api";
import logo from "../images/logo.png";

export default function Dev() {
  const [pageLoading, setPageLoading] = useState(false);

  const handleResetTimeSlots = async () => {
    try {
      setPageLoading(true);
      const response = await resetTimeSlots();
      console.log("Reset Time Slots", response);
      setPageLoading(false);
    } catch (error) {
      console.log("Error resetting time slots", error);
    } finally {
      setPageLoading(false);
    }
  };

  const handleUpdateTimeSlot = async () => {
    try {
      setPageLoading(true);
      const response = await updateTimeSlot();
      console.log("Update Time Slot", response);
    } catch (error) {
      console.log("Error updating time slot", error);
    } finally {
      setPageLoading(false);
    }
  };

  return (
    <section className="flex flex-col justify-center items-center">
      {pageLoading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <>
          <div className="flex flex-col justify-center items-center">
            <img src={logo} alt="logo" className="" style={{ width: "150px", height: "150px" }} />
            <div className="flex justify-center items-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m6.75 7.5 3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0 0 21 18V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v12a2.25 2.25 0 0 0 2.25 2.25Z"
                />
              </svg>
              <h1 className="text-2xl font-bold ml-2">Development</h1>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center bg-gray-100 rounded-primary p-4 mt-10 shadow-sm">
            <p className="text-md text-left w-full">Time Slots</p>
            <div className="flex justify-center w-full">
              <button
                className="bg-black text-white py-3 px-20 mx-4 my-2 rounded-secondary font-semibold transition-colors pointer-events-auto font-inter"
                onClick={handleResetTimeSlots}
              >
                Reset Time Slots
              </button>
            </div>
            <div className="flex justify-center w-full">
              <button
                className="bg-black text-white py-3 px-20 mx-4 my-2 rounded-secondary font-semibold transition-colors pointer-events-auto font-inter"
                onClick={handleUpdateTimeSlot}
              >
                Update Time Slots
              </button>
            </div>
          </div>
        </>
      )}
    </section>
  );
}
