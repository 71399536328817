import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

// Pay-by-minute
import Registration from "./pages/payByMinute/Registration";
import PaymentCapture from "./pages/payByMinute/PaymentCapture";
import SessionSetup from "./pages/payByMinute/SessionSetup";
import Checkout from "./pages/payByMinute/Checkout";

// Reservation
import TimeScheduler from "./pages/reservation/TimeScheduler";
import SelectBox from "./pages/reservation/SelectBox";
import StartSession from "./pages/reservation/StartSession";
import ConfirmReservation from "./pages/reservation/ConfirmReservation";
import ReservationCheckIn from "./pages/reservation/ReservationCheckIn";
import SessionCancel from "./pages/reservation/SessionCancel";

// Dev
import Dev from "./pages/Dev";

const env = process.env.REACT_APP_ENV;

export default function App() {
  return (
    <Router>
      <Routes>
        {/* Pay-by-minute */}
        <Route path="/register" element={<Registration />} />
        <Route path="/payment-capture" element={<PaymentCapture />} />
        <Route path="/session/new" element={<SessionSetup />} />
        <Route path="/checkout/:token" element={<Checkout />} />

        {/* Reservation */}
        <Route path="/:locationId/reservation/time-scheduler" element={<TimeScheduler />} />
        <Route path="/reservation" element={<Navigate to="/:locationId/reservation/time-scheduler" replace />} />
        <Route path="/:locationId/reservation/select-box" element={<SelectBox />} />
        <Route path="/:locationId/reservation/start-session" element={<StartSession />} />
        <Route path="/:locationId/reservation/confirm" element={<ConfirmReservation />} />
        <Route path="/" element={<Registration />} />
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route path="/reservation/session/:sessionId" element={<ReservationCheckIn />} />
        <Route path="/reservation/session-cancel/:sessionId" element={<SessionCancel />} />
        {env === "development" && <Route path="/dev" element={<Dev />} />}
      </Routes>
    </Router>
  );
}
