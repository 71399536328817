// React
import { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

// Components
import logo from "../../images/logo.png";
import { Icons } from "../../components/Icons";
import ProgressStepper from "../../components/ProgressStepper";
import CommunityGuidelines from "../../components/CommunityGuidelines";
import { formatTime } from "../../utils/timeFormatter";
import { reserveBooth, checkTermsAndEmail, cancelLockedSession, sendCode, approveCode, checkTerms, verifyBoothAvailability } from "../../services/api";
import { capitalizeFirstLetter } from "../../utils/textFormatter";
import { handlePhoneChange, formatPhoneforApi, handleEmailChange, validateEmail, validatePhone } from "./StartSession.util";

export default function StartSession() {
  const navigate = useNavigate();
  const [showGuidelines, setShowGuidelines] = useState(false);
  const [timer, setTimer] = useState(() => {
    const savedTimer = localStorage.getItem("timer");
    return savedTimer ? parseInt(savedTimer, 10) : 180;
  });

  // State from SelectBox
  const { boothId, boothType, boxPlacement, time, duration, endTime, sessionId } = useLocation().state || {};
  const { locationId } = useParams();

  if (!boothId) navigate(`/${locationId}/reservation/select-box`);

  useEffect(() => {
    if (!boothId) navigate(`/${locationId}/reservation/select-box`);
  }, [boothId, locationId, navigate]);

  // Reservation Info
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const [codeSentOut, setCodeSentOut] = useState(false);
  const [code, setCode] = useState("");
  const [paymentMethod, setPaymentMethod] = useState(false);
  const [codeSentOutBtnDelay, setCodeSentOutBtnDelay] = useState(false);
  // Error Code
  const [errorCode, setErrorCode] = useState(null);

  useEffect(() => {
    const savedTimerStart = localStorage.getItem("timerStart");
    const duration = 180000; // 3 minutes in milliseconds, fixed

    if (!savedTimerStart) {
      localStorage.setItem("timerStart", Date.now());
    }

    const updateTimer = () => {
      const startTime = parseInt(localStorage.getItem("timerStart"), 10);
      const currentTime = Date.now();
      const timeElapsed = currentTime - startTime;
      const newTimer = Math.max(duration - timeElapsed, 0);
      setTimer(Math.floor(newTimer / 1000)); // Convert milliseconds to seconds for display
      if (newTimer <= 0) {
        clearInterval(intervalId);
        localStorage.removeItem("timerStart"); // Reset the timer start time
        navigate(-1); // Navigate back if timer expires
      }
    };

    const intervalId = setInterval(updateTimer, 1000);

    return () => {
      clearInterval(intervalId);
      localStorage.removeItem("timerStart"); // Ensure timer start is cleared on component unmount
    };
  }, [navigate]);

  useEffect(() => {
    const verifyBooth = async () => {
      try {
        const response = await verifyBoothAvailability(boothId, time, endTime, locationId, sessionId);
        if (response.message === "Booth not available") navigate(-1);
      } catch (error) {
        console.log("Error checking booth availability", error);
      }
    };
    verifyBooth();
  }, [boothId, time, endTime, locationId]);

  const handlePhoneChangeWrapper = (element) => handlePhoneChange(element, phone, setPhone, setPhoneError);
  const handleEmailChangeWrapper = (element) => handleEmailChange(element, setEmail);

  const handleGuidelines = async () => {
    // Reset error states before validation
    setEmailError(false);
    setPhoneError(false);

    const isEmailValid = await validateEmail(email);
    !isEmailValid && setEmailError("Not a valid email address.");

    if (isEmailValid) {
      try {
        const response = await checkTermsAndEmail(formatPhoneforApi(phone), email);

        // Terms Check
        if (response.message === "User not found") {
          // user doens't exist
          setShowGuidelines(true);
          return;
        } else {
          // Email Check
          if (!response.emailCheck) {
            // email attached to a different phone number
            setEmailError("This email is already used with a different phone number");
            return;
          } else {
            // email is not attached to a different phone number
            if (!response.acceptedTerms) {
              // terms are not accepted
              setShowGuidelines(true);
              return;
            } else {
              // terms are accepted
              return handleReserve();
            }
          }
        }
      } catch (error) {
        setErrorMessage(error.message);
      }
    } else {
      return;
    }
  };

  const handleReserve = async () => {
    setPageLoading(true);
    let reserveSession;
    try {
      const sessionData = {
        boothId,
        boothType,
        boxPlacement,
        time,
        duration,
        endTime,
        name: name,
        lastName: lastName,
        phone: formatPhoneforApi(phone),
        email: email,
        locationId,
        sessionId,
      };

      console.log("Session data", sessionData);
      reserveSession = await reserveBooth(sessionData); // Assign the result to reserveSession

      if (reserveSession.message === "Booth not available") {
        setErrorMessage("The booth is no longer available");
        return;
      }
    } catch (error) {
      setShowGuidelines(false);
      setErrorMessage(error.message);
    } finally {
      if (reserveSession && reserveSession.session) {
        navigate(`/${locationId}/reservation/confirm`, {
          state: {
            sessionData: reserveSession.session,
          },
        });
      } else {
        // Handle the case where reserveSession is not set due to an error
        console.error("Failed to reserve session");
      }
      setPageLoading(false);
    }
  };

  const handleGoBack = async () => {
    try {
      await cancelLockedSession(sessionId);
    } catch (error) {
      console.log("Error cancelling locked session", error);
    } finally {
      localStorage.removeItem("timerStart");
      navigate(-1);
    }
  };

  const handleSendCode = async () => {
    try {
      console.log("Sending code");
      setCodeSentOutBtnDelay(true);
      setTimeout(() => setCodeSentOutBtnDelay(false), 1000); // Reset delay after 3 seconds
      const isPhoneValid = await validatePhone(phone);
      if (!isPhoneValid) {
        setPhoneError(true);
        return;
      }

      const response = await sendCode(formatPhoneforApi(phone));
      setCodeSentOut(true);
    } catch (error) {
      if (error.message === "Incorrect phone number") {
        setPhoneError(true);
        return;
      }
      console.log("Error sending code", error.message, error);
      setErrorMessage("Error sending code");
    }
  };

  const handleCodeChange = (value) => {
    setErrorCode(null);
    if (value.length <= 4) {
      setCode(value);
      if (value.length === 4) {
        handleApproveCode(value);
      }
    }
  };

  const handleApproveCode = async (currentCode) => {
    try {
      console.log(currentCode);
      const response = await approveCode(formatPhoneforApi(phone), currentCode);
      if (response.moreInfoRequired) {
        setNewUser(true);
        return;
      } else {
        if (!response.termsAccepted) {
          console.log("Terms not accepted");
          setShowGuidelines(true);
        } else {
          console.log("Terms accepted");
          handleReserve();
        }
      }
    } catch (error) {
      if (error.message === "Invalid code") {
        setErrorCode(error.message);
        return;
      } else {
        console.error("Error approving code", error);
      }
    }
  };

  return (
    <section
      className="flex flex-col justify-center mb-8 
    md:px-mdPagePadding md:mx-smPagePadding
    lg:px-lgPagePadding lg:mx-lgPagePadding
    "
    >
      {pageLoading && (
        <div className="fixed inset-0 bg-white/80 flex items-center justify-center z-50">
          <div className="flex flex-col items-center">
            <div className="w-10 h-10 border-t-2 border-b-2 border-gray-900 rounded-full animate-spin"></div>
            <p className="mt-4 text-gray-900">Loading...</p>
          </div>
        </div>
      )}
      {errorMessage && (
        <div className="fixed  top-0 left-0 right-0 m-8 text-black text-sm text-center rounded-secondary py-3 z-50 border border-red-300 bg-red-50 shadow-lg animate-slideInDown">
          <div className="flex flex-col items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
              />
            </svg>
            <span className="mt-2">{errorMessage}</span>
          </div>
        </div>
      )}
      {showGuidelines && <CommunityGuidelines onClose={() => setShowGuidelines(false)} completeReservation={handleReserve} />}
      <div className="mt-10">
        <ProgressStepper currentStep={3} />
      </div>
      <div className="flex flex-col justify-center items-center">
        <img src={logo} alt="logo" className="" style={{ width: "150px", height: "150px" }} />
      </div>

      {/* -- Booth Details -- */}
      <div className="border-t border-b border-gray-200 py-5 mb-4 mx-smPagePadding flex flex-col justify-center items-center">
        <div className="flex justify-between items-center mb-2">
          <div className="flex gap-8 text-textSecondary">
            <span>{boothType && boothId ? `${capitalizeFirstLetter(boothType)} Booth #${boothId}` : ""}</span>
            <span> {time ? `${time} - ${endTime}` : ""}</span>
          </div>
        </div>
        <div className="flex items-center gap-3 text-textSecondary">
          McCormick
          <div className="h-4 w-px bg-gray-300"></div>
          {boxPlacement ? boxPlacement : ""}
        </div>
      </div>

      {/* -- Time Remaining -- */}
      <div className="flex flex-col justify-center items-center">
        <div className="mt-2 text-textSecondary px-4 py-2 mb-4 bg-gray-50 rounded-secondary border border-gray-200">Time Remaining: {formatTime(timer)}</div>
      </div>

      {/* -- Reservation Info -- */}
      <div className="mx-smPagePadding">
        <form
          className="flex flex-col mt-2 
        md:px-mdPagePadding 
        lg:px-20
        "
        >
          {!newUser && (
            <>
              <div>
                <label className="block text-sm text-textSecondary">Phone number</label>
                <div className="relative">
                  <input
                    type="text"
                    placeholder="+ 1 (234) 567-789"
                    required
                    className={`mt-2 block w-full py-3 px-4 border ${
                      phoneError ? "border-red-500" : "border-gray-300"
                    } rounded-secondary shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50`}
                    value={phone}
                    onChange={handlePhoneChangeWrapper}
                  />
                  <button
                    className={`absolute right-0 px-4 mr-1 flex items-center text-xs font-medium leading-4 text-white rounded-secondary ${
                      phone.length < 1 || codeSentOutBtnDelay ? "bg-gray-500" : "bg-gray-900"
                    }`}
                    disabled={phone.length < 1 || codeSentOutBtnDelay}
                    style={{ top: "50%", transform: "translateY(-50%)", height: "80%" }}
                    onClick={handleSendCode}
                  >
                    {codeSentOutBtnDelay ? Icons.phoneSpinner : Icons.sendCode}
                  </button>
                </div>
                {phoneError && (
                  <div className="text-red-500 text-sm mt-1">
                    <span>Not a valid phone number.</span>
                  </div>
                )}
              </div>
              <div className="mt-4">
                <label className="block text-sm text-textSecondary">4-digit code (sent via text)</label>
                <div className="relative">
                  <input
                    type="number"
                    placeholder="****"
                    required
                    className={`mt-2 block w-full py-3 px-4 border border-gray-300 rounded-secondary shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50
                  ${codeSentOut ? "bg-white" : "bg-gray-200"}
                  ${errorCode ? "border-red-500" : "border-gray-300"}
                  `}
                    disabled={!codeSentOut}
                    value={code}
                    onChange={(e) => {
                      handleCodeChange(e.target.value);
                    }}
                  />
                  {codeSentOut && (
                    <div className="absolute right-0 top-0 h-full flex items-center pr-4">
                      <div className="text-sm bg-green-300 px-3 py-1 rounded-md text-gray-900">Code sent!</div>
                    </div>
                  )}
                </div>
                {errorCode && (
                  <div className="text-red-500 text-sm mt-1">
                    <span>{errorCode}</span>
                  </div>
                )}
              </div>
            </>
          )}

          {/* -- New User -- */}
          {newUser && (
            <div className="flex flex-col mt-1">
              <div>
                <label className="block text-sm text-textSecondary">Phone number</label>
                <div className="flex justify-between items-center mt-2 w-full py-3 px-4 border border-gray-300 bg-gray-50 text-gray-500 rounded-secondary shadow-sm text-sm">
                  {phone}
                  <span className="bg-green-300 text-xs px-3 py-1 rounded-md text-gray-700">Approved</span>
                </div>
              </div>
              <div className="flex flex-row items-center gap-1 mt-6 md:mt-10">
                <div>{Icons.account}</div>
                <h3 className="text-sm text-textSecondary mr-2">Create an account</h3>
                <div className="flex-grow border-b border-gray-200"></div>
              </div>
              <div className="flex flex-col gap-4 mt-4">
                <div>
                  <label className="block text-sm text-textSecondary">First name</label>
                  <input
                    type="text"
                    placeholder="Michael"
                    required
                    className="mt-2 block w-full py-3 px-4 border border-gray-300 rounded-secondary shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </div>

                <div>
                  <label className="block text-sm text-textSecondary">Last name</label>
                  <input
                    type="text"
                    placeholder="Doe"
                    required
                    className="mt-2 block w-full py-3 px-4 border border-gray-300 rounded-secondary shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 text-sm"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>

                <div>
                  <label className="block text-sm text-textSecondary">Work email</label>
                  <input
                    type="email"
                    placeholder="you@example.com"
                    required
                    className={`mt-2 block w-full py-3 px-4 border text-sm ${
                      emailError ? "border-red-500" : "border-gray-300"
                    } rounded-secondary shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50`}
                    value={email}
                    onChange={handleEmailChangeWrapper}
                  />
                  {emailError && (
                    <div className="text-red-500 text-sm mt-1">
                      <span>{emailError}</span>
                    </div>
                  )}
                </div>
              </div>
              {/* -- Payment Method -- */}
              {paymentMethod && (
                <div className="flex flex-col mt-8">
                  <div className="flex flex-row items-center gap-1">
                    <div>{Icons.payment}</div>
                    <h3 className="text-sm text-textSecondary mr-2">Add payment method</h3>
                    <div className="flex-grow border-b border-gray-200"></div>
                  </div>
                  <div className="flex flex-col bg-gray-50 gap-4 px-2 py-4 rounded-secondary mt-2">
                    <div>Payment Method</div>
                  </div>
                </div>
              )}
            </div>
          )}
        </form>
        <div
          className={` left-0 right-0 flex justify-center items-center z-50 pointer-events-none mt-5 
          md:mt-10 md:mx-12 
          lg:mt-12 lg:mx-20
          ${newUser ? "" : "py-12 mt-12"}`}
        >
          <button className={` bg-white px-4 py-3 rounded-secondary border border-gray-400 pointer-events-auto`} onClick={() => handleGoBack()}>
            <i className="fas fa-arrow-left fa-lg"></i>
          </button>
          {/* {!newUser ? (
            code.length > 0 ? (
              <button
                className={`bg-white hover:bg-gray-700 text-black w-full py-3 ml-7 border border-gray-400 rounded-secondary font-semibold transition-colors pointer-events-auto font-inter`}
                onClick={() => handleApproveCode(code)}
                disabled={!phone}
              >
                Approve code
              </button>
            ) : (
              <button
                className={`text-black w-full py-3 ml-7 border border-gray-400 rounded-secondary font-inter font-semibold
                  ${phone.length < 1 ? "bg-gray-100" : "bg-white hover:bg-gray-100 pointer-events-auto"}
                `}
                disabled={phone.length < 1 || codeSentOutBtnDelay}

                onClick={handleSendCode}
              >
                <div className="flex justify-center w-full">
                  {codeSentOutBtnDelay && Icons.spinner}
                  Send Code
                </div>
              </button>
            )
          ) : ( */}
          <button
            className={`${
              !name || !lastName || !phone || !email ? "bg-gray-400" : "bg-black hover:bg-gray-700"
            } text-white w-full py-3 ml-7 rounded-secondary font-semibold transition-colors pointer-events-auto font-inter`}
            onClick={() => handleGuidelines()}
            disabled={!name || !lastName || !phone || !email}
          >
            Continue
          </button>
          {/* )} */}
        </div>
      </div>
    </section>
  );
}
