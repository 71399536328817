import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

// Services
import { getCheckInSession, cancelSession } from "../../services/api";

// Utils
import { dateToTime } from "../../utils/timeFormatter";
import { capitalizeFirstLetter } from "../../utils/textFormatter";

// Components
import SessionCancel from "./SessionCancel";
import ActiveSession from "./ActiveSession";
import logo from "../../images/logo.png";
import { qrCode, confirmActionIcon, Icons } from "../../components/Icons";
import ConfirmAction from "../../components/ConfirmAction";
import FinishedSession from "./FinishedSession";

export default function ReservationCheckIn() {
  const navigate = useNavigate();
  const { sessionId } = useParams();
  const [errorMessage, setErrorMessage] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [session, setSession] = useState(null);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [user, setUser] = useState(null);
  const [isCancelled, setIsCancelled] = useState(false);
  const [activeSession, setActiveSession] = useState(false);
  const [isFinished, setIsFinished] = useState(false);

  useEffect(() => {
    const fetchSession = async () => {
      try {
        setPageLoading(true);
        const response = await getCheckInSession(sessionId);
        setSession(response.session);
        setUser(response.user);
        if (response.session.status === "cancelled") {
          setIsCancelled(true);
        }
      } catch (error) {
        setErrorMessage(error.message);
        if (error.message === "Session not found") {
          navigate("/0500550214/reservation/time-scheduler");
        }
      } finally {
        setPageLoading(false);
      }
    };
    setShowCancelConfirmation(false);
    fetchSession();
  }, [sessionId]);

  const handleCancel = () => {
    setShowCancelConfirmation(true);
  };

  const confirmCancellation = async () => {
    try {
      setPageLoading(true);
      const response = await cancelSession(sessionId, user.phoneNumber);
      console.log("Cancelled session", response);
      setIsCancelled(true);
    } catch (error) {
      setShowCancelConfirmation(false);
      setErrorMessage(error.message);
    } finally {
      setPageLoading(false);
    }
  };

  const dismissConfirmation = () => {
    setShowCancelConfirmation(false);
  };

  // Function to handle click outside the popup
  const handleBackdropClick = (event) => {
    if (event.target.id === "backdrop") {
      dismissConfirmation();
    }
  };

  if (isCancelled) return <SessionCancel sessionData={session} />;

  if (isFinished) return <FinishedSession sessionData={session} />;

  if (activeSession) return <ActiveSession sessionData={session} />;

  return (
    <section
      className="flex flex-col justify-center mt-1 mb-4 
    md:mx-smPagePadding md:px-mdPagePadding 
    lg:mx-lgPagePadding lg:px-lgPagePadding
    "
    >
      {/* Page Loading */}
      {pageLoading && (
        <div className="fixed inset-0 bg-white/80 flex items-center justify-center z-50">
          <div className="flex flex-col items-center">
            <div className="w-10 h-10 border-t-2 border-b-2 border-gray-900 rounded-full animate-spin"></div>
          </div>
        </div>
      )}
      {/* Error Message */}
      {errorMessage && (
        <div className="fixed  top-0 left-0 right-0 m-8 text-black text-sm text-center py-3 z-49 border border-red-300 rounded-secondary bg-red-50 shadow-lg animate-slideInDown">
          <div className="flex flex-col items-center">
            {Icons.error}
            <span className="mt-2">{errorMessage}</span>
          </div>
        </div>
      )}
      {/* Logo */}
      <div className="flex flex-col justify-center items-center">
        <img src={logo} alt="logo" className="" style={{ width: "150px", height: "150px" }} />
      </div>
      {/* Session Instructions */}
      <div
        className="mx-smPagePadding flex flex-col justify-center items-start text-start py-3 px-4 rounded-primary border border-gray-200 bg-gray-50
      "
      >
        <h1 className="text-lg font-bold mb-2 py-2 md:text-l">Start Session</h1>
        <div className="px-3 py-2 border-l-2 border-gray-300 mb-2">
          <p className="text-textSecondary text-sm md:sm">
            1. Scan the QR Code to unlock the booth. <br />
            2. Enjoy your session! <br />
            3. Use the QR Code to check out when done. <br />
            <br />
            Easy peasy!
          </p>
        </div>
      </div>
      {/* Booth Details */}
      <div className="border-t border-b border-gray-200 py-5 mx-smPagePadding flex flex-col justify-center items-center mb-9 mt-5">
        <div className="flex justify-between items-center mb-2">
          <div className="flex gap-8 text-textSecondary">
            <span>{session ? `${capitalizeFirstLetter(session.boothType)} Booth #${session.boothId}` : ""}</span>
            <span>{session ? `${dateToTime(session.startTime)} - ${dateToTime(session.endTime)}` : ""}</span>
          </div>
        </div>
        <div className="flex items-center gap-3 text-textSecondary">
          McCormick
          <div className="h-4 w-px bg-gray-300"></div>
          {session ? session.boxPlacement : ""}
        </div>
      </div>
      {/* QR Code */}
      <div className="mx-smPagePadding flex flex-col justify-center items-center rounded-primary">{qrCode.activeSession(`check-in/${sessionId}`)}</div>

      {/* Cancel Confirmation */}
      <ConfirmAction
        isVisible={showCancelConfirmation}
        onConfirm={confirmCancellation}
        onCancel={dismissConfirmation}
        confirmText="Yes"
        cancelText="No"
        message="Are you sure you want to cancel?"
        confirmColor="red"
        icon={confirmActionIcon.cancel}
      />

      <div
        className="mx-smPagePadding bottom-0 left-0 right-0 mt-6 flex flex-col justify-center items-center py-4 z-49 pointer-events-none 
      md:px-16 md:mt-16
      lg:px-20 lg:mt-20
      "
      >
        <button
          className={`text-black bg-red-200 w-full p-3 mb-2 rounded-secondary transition-colors pointer-events-auto border border-gray-300 font-inter flex justify-center items-center hover:bg-gray-700 font-semibold`}
          onClick={handleCancel}
        >
          Cancel
        </button>

        <p className="text-textSecondary text-xs mt-2">
          Need help?{" "}
          <a href="mailto:support@pebble.com">
            Contact support at <u>support@pebble.com</u>
          </a>
        </p>
      </div>
    </section>
  );
}
