// React
import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

// Components
import { getEndTime, transformStartTime } from "../../components/formattedTime";
import ContinueBtn from "../../components/ContinueBtn";
import ProgressStepper from "../../components/ProgressStepper";
import logo from "../../images/logo.png";
import { getAvailableBooths, temproraryReservation } from "../../services/api";
import { capitalizeFirstLetter } from "../../utils/textFormatter";

// Env
const env = process.env.REACT_APP_ENV;

export default function SelectBox() {
  const navigate = useNavigate();
  const location = useLocation();
  const { locationId } = useParams();
  const { startTime, duration, boothType } = location.state || {};

  if (!startTime || !duration || !boothType) navigate(`/${locationId}/reservation/time-scheduler`);

  const [errorMessage, setErrorMessage] = useState(false);
  const [selectedBooth, setSelectedBooth] = useState(null);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [availableBooths, setAvailableBooths] = useState([]);
  const [groupedBuildings, setGroupedBuildings] = useState([]);

  // Add a ref to store the timeout ID
  const errorTimeoutRef = useRef(null);

  // Update setErrorMessage to handle timeout
  const displayErrorMessage = (message) => {
    // Clear any existing timeout to reset the error message
    if (errorTimeoutRef.current) {
      clearTimeout(errorTimeoutRef.current);
    }

    setErrorMessage(message);

    // Set a new timeout to clear the error message after 7 seconds
    errorTimeoutRef.current = setTimeout(() => {
      setErrorMessage(false);
      errorTimeoutRef.current = null; // Clear the ref once the timeout is done
    }, 7000);
  };

  // Define fetchBooths outside of useEffect to make it accessible throughout the component
  const fetchBooths = async () => {
    setPageLoading(true);
    try {
      const booths = await getAvailableBooths({ startTime, duration, boothType, locationId });

      if (booths.message === "No available booths found at this time") {
        setAvailableBooths([]);
      } else {
        setAvailableBooths(booths.availableBooths);

        // Sort and group booths by building
        const groupedBuildings = booths.availableBooths.reduce((acc, booth) => {
          if (!booth?.location?.boxPlacement) return acc;

          const building = booth.location.boxPlacement;
          const boxId = booth.boothId;

          const existingBuilding = acc.find((item) => item.building === building);
          if (existingBuilding) {
            existingBuilding.availableBoxes.push(boxId);
          } else {
            acc.push({
              building,
              availableBoxes: [boxId],
            });
          }
          return acc;
        }, []);

        // Sort buildings alphabetically
        groupedBuildings.sort((a, b) => a.building.localeCompare(b.building));

        // Remove default selection
        setGroupedBuildings(groupedBuildings);
      }
    } catch (error) {
      console.error("Failed to fetch available booths:", error);
      env === "development" ? setErrorMessage("Failed to fetch available booths") : setErrorMessage("An error occurred while fetching the available booths", error.message);
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    fetchBooths();
  }, [startTime, duration, boothType, locationId]);

  const handleBuildingChange = (building) => {
    setSelectedBuilding(building);
    // Remove the automatic selection of the first box in the building
    setSelectedBooth(null);
  };
  const handleBoxChange = (boxNumber) => setSelectedBooth(boxNumber);

  const continueToStartSession = async () => {
    try {
      setPageLoading(true);
      const session = await temproraryReservation(selectedBooth, selectedBuilding, startTime, getEndTime(startTime, duration), locationId);
      setPageLoading(false);

      navigate(`/${locationId}/reservation/start-session`, {
        state: {
          sessionId: session.sessionId,
          boothId: selectedBooth,
          boothType: boothType,
          boxPlacement: selectedBuilding,
          time: transformStartTime(startTime),
          duration: duration,
          endTime: getEndTime(startTime, duration),
        },
      });
    } catch (error) {
      if (error.message === "The booth is no longer available") {
        await fetchBooths();
        displayErrorMessage("The booth is no longer available");
        return;
      } else {
        env === "development" && console.log("Error", error.message);
        displayErrorMessage("An error occurred while reserving the booth");
        return;
      }
    }
  };

  return (
    <section
      className="flex flex-col justify-center mb-20 pb-10 
    md:px-mdPagePadding md:mx-smPagePadding
    lg:px-lgPagePadding lg:mx-lgPagePadding 
    "
    >
      {errorMessage && (
        <div className="fixed  top-0 left-0 right-0 m-8 text-black text-sm text-center py-3 z-50 border border-red-300 rounded-secondary bg-red-50 shadow-lg animate-slideInDown">
          <div className="flex flex-col items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
              />
            </svg>
            <span className="mt-2">{errorMessage}</span>
          </div>
        </div>
      )}
      {/* -- Progress Stepper and Logo -- */}
      <div className="mt-10">
        <ProgressStepper currentStep={2} />
      </div>
      <div className="flex flex-col justify-center items-center p-2">
        <img src={logo} alt="logo" className="" style={{ width: "150px", height: "150px" }} />
      </div>

      {/* -- Booth Info -- */}
      <div className="border-t border-b border-gray-200 py-5 mx-smPagePadding flex flex-col justify-center items-center mb-9">
        <div className="flex justify-between items-center mb-2">
          <div className="flex gap-4 text-textSecondary">
            <span>
              {boothType ? capitalizeFirstLetter(boothType) : ""} booth #{selectedBooth || ""}
            </span>
            <span className="">
              {transformStartTime(startTime)} - {getEndTime(startTime, duration)}
            </span>
          </div>
        </div>
        <div className="flex items-center gap-3 text-textSecondary">
          McCormick
          <div className="h-4 w-px bg-gray-300"></div>
          {selectedBuilding}
        </div>
      </div>

      {/* -- Pick a building -- */}
      <div className="flex flex-col gap-4 mx-smPagePadding">
        {groupedBuildings.length > 0 ? (
          groupedBuildings.map((building) => (
            <div key={building.building} className="flex flex-col">
              <label className="cursor-pointer">
                <input
                  type="radio"
                  name="building"
                  value={building.building}
                  className="peer hidden"
                  checked={selectedBuilding === building.building}
                  onChange={() => {
                    handleBuildingChange(building.building);
                  }}
                />
                <div className="p-4 rounded-primary border border-gray-200 peer-checked:border-black hover:border-black hover:shadow-md transition-all duration-200">
                  <h3 className="font-medium mb-1">{building.building}</h3>
                  <p className="text-textSecondary text-sm">Available box #: {building.availableBoxes.join(", ")}</p>
                </div>
                <div className="grid grid-rows-[0fr] peer-checked:grid-rows-[1fr] transition-all duration-300">
                  <div className="overflow-hidden">
                    <div className="grid grid-cols-4 md:grid-cols-6 lg:grid-cols-8 gap-4 w-full pt-4">
                      {building.availableBoxes.map((boxNumber) => (
                        <label key={boxNumber} className="cursor-pointer aspect-[1.5/1]">
                          <input
                            type="radio"
                            name="boxNumber"
                            value={boxNumber}
                            className="peer hidden"
                            checked={selectedBooth === boxNumber}
                            onChange={() => handleBoxChange(boxNumber)}
                          />
                          <div
                            className="w-full h-16 flex items-center justify-center rounded-primary border border-gray-200 peer-checked:border-black hover:border-black hover:shadow-md transition-all duration-200 text-xl"
                            onClick={() => handleBoxChange(boxNumber)}
                          >
                            {boxNumber}
                          </div>
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
              </label>
            </div>
          ))
        ) : pageLoading ? (
          ""
        ) : (
          <div className="flex flex-col gap-1 justify-center items-center text-center p-4 rounded-secondary">
            <div className="flex items-start">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                />
              </svg>

              <p className="text-black text-m ml-2">No available {boothType} booths found at this time...</p>
            </div>
          </div>
        )}
      </div>
      <ContinueBtn btnBack={true} disabled={!selectedBooth || !selectedBuilding} onClick={continueToStartSession} />

      {pageLoading && (
        <div className="fixed inset-0 bg-white/80 flex items-center justify-center z-50">
          <div className="flex flex-col items-center">
            <div className="w-10 h-10 border-t-2 border-b-2 border-gray-900 rounded-full animate-spin"></div>
          </div>
        </div>
      )}
    </section>
  );
}
